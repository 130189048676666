.ItemImage {
  position: relative;
}

.ItemImage.image-wrapper {
  border-radius: 5px;
}

.ItemImage.image-wrapper .item-image {
  width: auto;
  max-width: 100%;
}

.ItemImage .ItemBadge {
  position: absolute;
  bottom: 9px;
  left: 9px;
}

.ItemImage .SmartBadge {
  position: absolute;
  bottom: 9px;
  left: 50px;
}

.ItemImage .SmartBadge.small {
  left: 32px;
}
