.ui.small.modal.TutorialModal {
  width: 640px;
}

.ui.small.modal.TutorialModal .content {
  display: flex;
  flex-direction: column;
}

.TutorialModal .step {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: white;
  opacity: 0.16;
  cursor: pointer;
}

.TutorialModal .title {
  font-size: var(--modal-title-font-size);
}

.TutorialModal .subtitle {
  font-size: 17px;
  font-weight: 300;
  margin-top: 16px;
  line-height: 26px;
}

.TutorialModal .step.active {
  opacity: 1;
}

.TutorialModal .actions {
  display: flex;
  justify-content: space-between;
}

.TutorialModal .actions > .ui.button {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: auto;
}

.TutorialModal .actions .spacer {
  width: 128px;
}

.TutorialModal .steps {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TutorialModal .steps .step:not(:first-child) {
  margin-left: 10px;
}

.TutorialModal .slide {
  width: 576px;
  height: 320px;
  border-radius: 8px;
  background: var(--bluish-steel);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  user-select: none;
}

.TutorialModal .form {
  flex: 1;
  margin-bottom: 60px;
}

.TutorialModal.ui.modal > .actions > .ui.button {
  flex: none;
}

.TutorialModal .header {
  position: relative;
}

.TutorialModal .skip-tutorial {
  position: absolute;
  right: 0px;
}
