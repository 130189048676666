.Icon.add {
	background: url("icons.svg") 58.992805755395686% 99.1515873015873% no-repeat;
	width: 13px;
	height: 13px;
}

.Icon.add-active {
	background: url("icons.svg") 68.34532374100719% 99.1515873015873% no-repeat;
	width: 13px;
	height: 13px;
}

.Icon.alert {
	background: url("icons.svg") 40.57971014492754% 87.2% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.arrow-down {
	background: url("icons.svg") 98.59154929577464% 16.541353383458645% no-repeat;
	width: 10px;
	height: 6px;
}

.Icon.arrow-key-down {
	background: url("icons.svg") 50.72463768115942% 87.2% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.arrow-key-left {
	background: url("icons.svg") 60.869565217391305% 87.2% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.arrow-key-right {
	background: url("icons.svg") 71.01449275362319% 87.2% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.arrow-key-up {
	background: url("icons.svg") 81.15942028985508% 87.2% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.arrow-up {
	background: url("icons.svg") 98.59154929577464% 21.05263157894737% no-repeat;
	width: 10px;
	height: 6px;
}

.Icon.atlas-zoom-in {
	background: url("icons.svg") 85.71428571428571% 98.3708661417323% no-repeat;
	width: 12px;
	height: 12px;
}

.Icon.atlas-zoom-out {
	background: url("icons.svg") 82.85714285714286% 75.91240875912409% no-repeat;
	width: 12px;
	height: 2px;
}

.Icon.camera {
	background: url("icons.svg") 0 0 no-repeat;
	width: 28px;
	height: 22px;
}

.Icon.check {
	background: url("icons.svg") 21.875% 0 no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.chevron-right {
	background: url("icons.svg") 91.66666666666667% 0 no-repeat;
	width: 8px;
	height: 14px;
}

.Icon.close {
	background: url("icons.svg") 0 99.9448% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.cloud-upload {
	background: url("icons.svg") 0 18.96551724137931% no-repeat;
	width: 28px;
	height: 23px;
}

.Icon.delete {
	background: url("icons.svg") 10% 87.90322580645162% no-repeat;
	width: 12px;
	height: 15px;
}

.Icon.duplicate {
	background: url("icons.svg") 40.57971014492754% 56.09756097560975% no-repeat;
	width: 14px;
	height: 16px;
}

.Icon.edit {
	background: url("icons.svg") 51.470588235294116% 56.09756097560975% no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.ellipsis {
	background: url("icons.svg") 38.80597014925373% 28.14814814814815% no-repeat;
	width: 18px;
	height: 4px;
}

.Icon.export {
	background: url("icons.svg") 62.31884057971015% 56.09756097560975% no-repeat;
	width: 14px;
	height: 16px;
}

.Icon.facebook {
	background: url("icons.svg") 17.142857142857142% 58.97435897435897% no-repeat;
	width: 12px;
	height: 22px;
}

.Icon.geometries {
	background: url("icons.svg") 77.14285714285714% 99.1515873015873% no-repeat;
	width: 12px;
	height: 13px;
}

.Icon.grid {
	background: url("icons.svg") 98.59154929577464% 26.356589147286822% no-repeat;
	width: 10px;
	height: 10px;
}

.Icon.grid-active {
	background: url("icons.svg") 98.59154929577464% 34.10852713178294% no-repeat;
	width: 10px;
	height: 10px;
}

.Icon.heart {
	background: url("icons.svg") 21.21212121212121% 19.834710743801654% no-repeat;
	width: 20px;
	height: 18px;
}

.Icon.heart-full {
	background: url("icons.svg") 27.272727272727273% 57.02479338842975% no-repeat;
	width: 20px;
	height: 18px;
}

.Icon.import {
	background: url("icons.svg") 0 88.56820157797658% no-repeat;
	width: 14px;
	height: 15.931px;
}

.Icon.list {
	background: url("icons.svg") 21.73913043478261% 76.92307692307692% no-repeat;
	width: 14px;
	height: 9px;
}

.Icon.list-active {
	background: url("icons.svg") 31.884057971014492% 76.92307692307692% no-repeat;
	width: 14px;
	height: 9px;
}

.Icon.locate-land {
	background: url("icons.svg") 10.144927536231885% 99.9448% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.minus {
	background: url("icons.svg") 40.625% 0 no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.modal-back {
	background: url("icons.svg") 91.66666666666667% 11.2% no-repeat;
	width: 8px;
	height: 14px;
}

.Icon.modal-close {
	background: url("icons.svg") 20.28985507246377% 99.9448% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.move {
	background: url("icons.svg") 73.52941176470588% 0 no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.move-active {
	background: url("icons.svg") 73.52941176470588% 13.008130081300813% no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.pin {
	background: url("icons.svg") 0 39.130434782608695% no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.pin-active {
	background: url("icons.svg") 18.75% 39.130434782608695% no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.plus {
	background: url("icons.svg") 37.5% 39.130434782608695% no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.preview {
	background: url("icons.svg") 73.52941176470588% 24.8062015503876% no-repeat;
	width: 16px;
	height: 10px;
}

.Icon.preview-active {
	background: url("icons.svg") 73.52941176470588% 32.55813953488372% no-repeat;
	width: 16px;
	height: 10px;
}

.Icon.profile {
	background: url("icons.svg") 59.375% 0 no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.reset-camera {
	background: url("icons.svg") 73.52941176470588% 42.27642276422764% no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.right-round-arrow {
	background: url("icons.svg") 73.52941176470588% 55.28455284552845% no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.rotate {
	background: url("icons.svg") 0 75.60975609756098% no-repeat;
	width: 15px;
	height: 16px;
}

.Icon.rotate-active {
	background: url("icons.svg") 10.94890510948905% 75.60975609756098% no-repeat;
	width: 15px;
	height: 16px;
}

.Icon.rotate-left {
	background: url("icons.svg") 73.52941176470588% 63.63636363636363% no-repeat;
	width: 16px;
	height: 7px;
}

.Icon.rotate-right {
	background: url("icons.svg") 22.058823529411764% 70.45454545454545% no-repeat;
	width: 16px;
	height: 7px;
}

.Icon.scale {
	background: url("icons.svg") 85.29411764705883% 0 no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.scale-active {
	background: url("icons.svg") 85.29411764705883% 13.008130081300813% no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.scene-object {
	background: url("icons.svg") 81.69014084507042% 26.016260162601625% no-repeat;
	width: 10px;
	height: 16px;
}

.Icon.scene-parcel {
	background: url("icons.svg") 85.29411764705883% 39.02439024390244% no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.share {
	background: url("icons.svg") 30% 99.9448% no-repeat;
	width: 12px;
	height: 14px;
}

.Icon.shortcuts {
	background: url("icons.svg") 85.29411764705883% 49.6124031007752% no-repeat;
	width: 16px;
	height: 10px;
}

.Icon.sidebar {
	background: url("icons.svg") 39.130434782608695% 99.9448% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.sidebar-active {
	background: url("icons.svg") 49.27536231884058% 99.9448% no-repeat;
	width: 14px;
	height: 14px;
}

.Icon.smart {
	background: url("icons.svg") 85.29411764705883% 60.16260162601626% no-repeat;
	width: 16px;
	height: 16px;
}

.Icon.table {
	background: url("icons.svg") 59.375% 20.869565217391305% no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.table-active {
	background: url("icons.svg") 0 60% no-repeat;
	width: 24px;
	height: 24px;
}

.Icon.textures {
	background: url("icons.svg") 100% 0 no-repeat;
	width: 12px;
	height: 12px;
}

.Icon.triangles {
	background: url("icons.svg") 100% 9.30232558139535% no-repeat;
	width: 12px;
	height: 10px;
}

.Icon.twitter {
	background: url("icons.svg") 59.375% 40.33613445378151% no-repeat;
	width: 24px;
	height: 20px;
}

.Icon.undo {
	background: url("icons.svg") 85.29411764705883% 72% no-repeat;
	width: 16px;
	height: 14px;
}

.Icon.view {
	background: url("icons.svg") 40% 19.2% no-repeat;
	width: 22px;
	height: 14px;
}

.Icon.zoom-in {
	background: url("icons.svg") 18.978102189781023% 87.90322580645162% no-repeat;
	width: 15px;
	height: 15px;
}

.Icon.zoom-out {
	background: url("icons.svg") 29.927007299270073% 87.90322580645162% no-repeat;
	width: 15px;
	height: 15px;
}

