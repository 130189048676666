.PoolCard {
  position: relative;
  width: calc(33% - 15px);
  height: var(--list-page-card-height);
  border-radius: 12px;
  margin-bottom: 25px;
  margin-right: 25px;
  background: rgba(var(--bluish-steel-raw), 0.16);
  cursor: pointer;
}

.PoolCard:nth-child(3n) {
  margin-right: 0px;
}

.PoolCard .deployment-status {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 10px;
  height: 10px;
}

.PoolCard .project-data {
  width: 100%;
  margin: 20px;
  color: var(--text);
  z-index: 1;
}

.PoolCard .title {
  margin-bottom: 14px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.PoolCard .description {
  font-size: 17px;
  font-weight: 200;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
}

.PoolCard .thumbnail {
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: calc(var(--list-page-card-width) * 0.75);
}

.PoolCard .title-wrapper {
  display: flex;
}

.PoolCard .Icon.is-uploading {
  margin-left: 4px;
  margin-top: 2px;
}

.PoolCard .component-list {
  color: var(--light-gray);
  font-weight: 600;
  display: flex;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 24px;
  flex: 0 0 100%;
}

.PoolCard .component-list .component {
  margin-right: 24px;
}

.PoolCard .component-list .component .Icon {
  margin-right: 0.5em;
  vertical-align: top;
}

@media (max-width: 992px) {
  .PoolCard {
    width: calc(50% - 15px);
  }

  .PoolCard:nth-child(2n) {
    margin-right: 0px;
  }

  .PoolCard:nth-child(3n) {
    margin-right: 25px;
  }
}

@media (max-width: 592px) {
  .PoolCard {
    width: 100%;
  }

  .PoolCard {
    margin-right: 0px;
  }
}
