.Notice {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: var(--card);
  border-radius: 5px;
}

.Notice .text {
  flex: 1;
  font-size: 15px;
  text-align: center;
  color: var(--secondary-text);
}

.Notice .Icon {
  filter: invert(65%);
  cursor: pointer;
}
