.PushCollectionChangesModal .header,
.PushCollectionChangesModal .content {
  text-align: center;
}
.PushCollectionChangesModal.ui.modal > .dcl.modal-navigation {
  padding-top: 48px;
}

.PushCollectionChangesModal.ui.modal > .first-step,
.PushCollectionChangesModal.ui.modal > .second-step {
  padding: 0 20px 20px;
}

.PushCollectionChangesModal .loader-wrapper {
  height: 80px;
  position: relative;
}

.PushCollectionChangesModal .divider {
  width: 50%;
  margin: 25px auto;
  height: 1px;
  border: 2px solid var(--primary);
  border-radius: 2px;
}

.PushCollectionChangesModal .items-breakdown {
  padding: 20px 0;
  margin: 30px 0;
  border-radius: 10px;
  background-color: var(--background);
}

.PushCollectionChangesModal .items-breakdown .item {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;
  text-transform: capitalize;
}

.PushCollectionChangesModal .items-breakdown .item.total {
  border-top: 1px solid var(--secondary-text);
  color: var(--secondary-text);
  padding: 20px 20px 0;
}

.PushCollectionChangesModal .items-breakdown .item.total .mana {
  color: var(--text);
}

.PushCollectionChangesModal .items-breakdown .item i.item-rarity {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
}

.PushCollectionChangesModal .content .ui.button {
  margin-top: 60px;
}

.PushCollectionChangesModal .ui.button.proceed {
  margin-top: 0;
}

.PushCollectionChangesModal .not-enough-mana-notice {
  margin-top: 20px;
  display: inline-block;
}

.PushCollectionChangesModal .not-enough-mana-notice .dcl.mana .symbol {
  padding-right: 0;
}

.PushCollectionChangesModal .tos > p:not(:last-child) {
  margin-bottom: 25px;
}

/* Third step content */

.PushCollectionChangesModal .third-step.content {
  padding: 0;
}

.PushCollectionChangesModal .third-step.content .dcl.field {
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  width: auto;
}

.PushCollectionChangesModal .third-step.content .tos {
  background-color: var(--dark-gray);
  font-size: 15px;
  padding: 15px;
  text-align: left;
  color: var(--text);
  border-radius: 3px;
}

.PushCollectionChangesModal .third-step.content .ui.input > input {
  text-align: center;
}

/* Third step Footer */

.PushCollectionChangesModal .third-step-footer {
  display: flex;
  flex-direction: column;
}

.PushCollectionChangesModal .third-step-footer > p {
  color: var(--secondary-text);
  margin-top: 20px;
  font-size: 15px;
  text-align: center;
}

.PushCollectionChangesModal.ui.modal .actions.third-step-footer > button {
  margin-left: 40px;
  margin-right: 40px;
  width: auto;
}
