.SmartBadge {
  position: relative;
  background-color: white;
  border-radius: 3px;
  width: 32px;
  height: 32px;
  padding: 8px;
}

.SmartBadge .SmartIcon {
  filter: brightness(0);
}

.SmartBadge.small {
  width: 20px;
  height: 20px;
  padding: 0px 5px;
}

.SmartBadge.small .SmartIcon {
  width: 10px;
  height: 10px;
}
