.Metrics {
  font-size: 11px;
  display: flex;
  align-items: center;
}

.Metrics .SquaresGrid {
  margin-left: 5px;
  cursor: pointer;
}

.Metrics.metric-exceeded .Square {
  background-color: rgba(255, 0, 0, 0.4);
  border-color: var(--error);
}

.Metrics .metric {
  display: flex;
  margin-bottom: 12px;
}

.Metrics .metric .label {
  margin-right: 8px;
  text-transform: uppercase;
}

.Metrics .bubble .dimensions,
.Metrics .metric .value-limit {
  color: var(--bluish-steel);
}

.Metrics .bubble {
  position: absolute;
  background-color: var(--void);
  padding: 12px;
  border-radius: 6px;
  bottom: 36px;
  color: white;
  width: 260px;
}

.Metrics .bubble .bubble-title {
  font-size: 15px;
}

.Metrics .metric.exceeded .label,
.Metrics .metric.exceeded .value,
.Metrics .metric.exceeded .value-limit {
  color: var(--error);
}

.Metrics .bubble:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10px;
  border-top: 8px solid black;
  border-top-color: inherit;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  color: var(--void);
}

.Metrics .bubble .divider {
  height: 1px;
  width: 80%;
  margin: 10px auto;
  border-top: 1px solid var(--bluish-steel);
  opacity: 0.2;
}

.Metrics .value-too-high {
  margin-left: 12px;
  text-transform: uppercase;
  cursor: pointer;
}

.Metrics .value-too-high .Icon {
  margin-right: 4px;
  vertical-align: sub;
}
