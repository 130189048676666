.Icon {
  display: inline-block;
}

.Icon:before {
  content: '';
}

.Icon.clickeable {
  cursor: pointer;
}
