/* .ui.dropdown .menu {
  background-color: var(--light-gray);
} */

.ui.dropdown .menu > .divider {
  background-color: var(--smart-grey);
}

/* Semantic-UI: https://github.com/Semantic-Org/Semantic-UI/issues/4285#issuecomment-232974723 */
:global(.ui.dropdown).dropdown :global(.menu > .disabled.item) {
  pointer-events: auto;
}

:global(.ui.dropdown).dropdown :global(.menu > .disabled.item a) {
  cursor: default;
}
