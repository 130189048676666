.MultiSelect.ui.dropdown.inline {
  border: 1px solid var(--gray);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 0;
  margin-bottom: 8px;
}

.MultiSelect.ui.dropdown.inline.blank {
  padding: 10px 12px;
}

.MultiSelect.ui.disabled.dropdown {
  pointer-events: all;
}

.MultiSelect.ui.dropdown.inline .label {
  margin-bottom: 0;
  margin-right: 10px;
  padding: 6px 12px;
  color: var(--secondary-text);
  display: flex;
  align-items: center;
}

.MultiSelect.ui.dropdown.inline .info {
  margin-left: 5px;
}

.MultiSelect.ui.dropdown.inline .values {
  flex: 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.MultiSelect.ui.dropdown.inline .values .placeholder {
  color: var(--secondary-text);
  text-align: right;
}

.MultiSelect.ui.dropdown.inline .values .value {
  background-color: var(--smart-grey);
  padding: 2px 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 10px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}

.MultiSelect.ui.dropdown.inline .values .value .remove {
  width: 12px;
  height: 12px;
  background-image: url(../../../../icons/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 8px;
  margin-left: 4px;
  cursor: pointer;
}

.MultiSelect.ui.dropdown.inline .handle {
  width: 8px;
  height: 8px;
  background-image: url(../../../../images/select-active.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 6px;
  margin-left: 7px;
}

.MultiSelect.ui.dropdown.inline.blank .handle {
  background-image: url(../../../../images/select-inactive.svg);
}

.MultiSelect.ui.dropdown.inline.blank .label,
.MultiSelect.ui.dropdown.inline.blank .handle {
  padding: 0;
  margin-bottom: 0;
}

.MultiSelect.ui.dropdown.inline .menu {
  max-height: 50vh;
  overflow: auto;
}
