.MintItemsModal .ui.loader {
  position: relative;
  left: 0;
  transform: none;
  margin: 50px auto;
}

.MintItemsModal .add-item {
  margin-top: 24px;
  font-size: 13px;
  text-transform: uppercase;
}

.MintItemsModal .unsynced-warning {
  text-align: center;
  margin-bottom: 2rem;
}

.MintItemsModal .actions {
  margin-top: 150px;
  width: 100%;
  text-align: right;
}

.MintItemsModal .actions .ui.button {
  padding: 10px 70px;
}

.MintItemsModal .ItemDropdown .placeholder {
  color: var(--primary);
  font-size: 13px;
  text-transform: uppercase;
}

.MintItemsModal .empty {
  background-color: var(--dark-gray);
  border-radius: 8px;
  margin-top: 40px;
  padding: 50px 25px;
  text-align: center;
}

.MintItemsModal .empty ~ .actions {
  margin-top: 40px;
}

.MintItemsModal .dcl.row {
  margin-top: 10px;
}
