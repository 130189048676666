:root {
  --top-panel-height: 56px;
}

.TopPanel {
  display: flex;
  align-items: center;
  height: var(--top-panel-height);
  position: relative;
}

.TopPanel .back {
  width: 52px;
  height: 52px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(../../../images/back-item-editor.svg);
  background-size: 8px;
}

.TopPanel .title {
  display: flex;
  align-items: center;
  flex: 1;
  font-weight: 600;
  font-size: 15px;
}

.TopPanel .actions {
  text-align: right;
}

.TopPanel .actions .button-container {
  display: flex;
  align-items: center;
}

.TopPanel .actions .button-container .check.circle.icon {
  color: #36d41d;
  margin-left: 8px;
  background-color: white;
  border-radius: 10px;
  width: 16px;
  height: 16px;
}

.TopPanel .actions .button-container .header {
  color: var(--text);
  margin-right: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.TopPanel .actions .ui.button {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 8px;
}

.TopPanel .dropdown .menu > .disabled.item {
  pointer-events: all;
}
