.JumpIn {
  display: inline-block;
  background-color: var(--primary);
  background-image: url(../../images/jump-in.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin-left: 8px;
}

.JumpIn.small {
  background-size: 14px;
  width: 22px;
  height: 22px;
}

.JumpIn.medium {
  background-size: 20px;
  width: 32px;
  height: 32px;
}

.JumpIn.large {
  background-size: 25px;
  width: 42px;
  height: 42px;
}
