.SceneListPage {
  position: relative;
}

.SceneListPage .ui.container {
  position: relative;
}

.SceneListPage .dcl.section.navigation {
  margin-top: 24px;
}

.SceneListPage .empty-projects {
  display: flex;
  width: 100%;
  height: 200px;
  border-radius: 12px;
  border: 1px solid var(--divider);
  align-items: center;
  justify-content: center;
  color: var(--secondary-text);
  user-select: none;
  text-align: center;
  line-height: 28px;
}

.SceneListPage .PoolCardList {
  display: flex;
  flex-wrap: wrap;
  min-height: 400px;
}

.SceneListPage .ui.pagination.menu {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  width: 100%;
}

.SceneListPage .ui.pagination.menu .item[type='nextItem'],
.SceneListPage .ui.pagination.menu .item[type='prevItem'] {
  position: absolute;
  background-color: var(--secondary);
}

.SceneListPage .ui.pagination.menu .item[type='prevItem'] {
  left: 0px;
}

.SceneListPage .ui.pagination.menu .item[type='nextItem'] {
  right: 0px;
}

@media (max-width: 768px) {
  .SceneListPage .tabs-menu {
    right: 20px;
    bottom: 11px;
  }
}
