.Collections {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Collections.dcl.section.medium {
  margin-bottom: 0;
}

.Collections .ReactVirtualized__List {
  padding: 0px 16px;
  margin-bottom: 16px;
}

.Collections .ReactVirtualized__Grid__innerScrollContainer {
  margin-bottom: 16px;
}

.Collections .ui.pagination.menu {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 16px;
  padding-top: 16px;
}
