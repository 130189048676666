.Drawer {
  /* 
  These 32px make up for the 16px of padding on both sides. 
  If we used padding, then the width of the Drawer 
  would be (100% - 16px - 16px - scrollbarWidth). We could compensate
  the scrollbarWidth by having less padding-right, but the scrollbarWidth
  changes among browsers and OS's and also it's 0 when the content is not 
  scrollable. By using the rule below we make sure that the width of the
  Drawer is constant, and the extra space to the right can me taken by
  the scrollbar if needed, without breaking the layout.
  */
  width: calc(var(--sidebar-width) - 32px);
  user-select: none;
}

.Drawer .heading {
  width: 100%;
  height: 32px;
  color: var(--bluish-steel);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.Drawer .label {
  text-transform: uppercase;
  font-size: 13px;
}

.Drawer .body {
  width: 100%;
}
