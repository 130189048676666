.ActivityPage .history-header {
  display: flex;
}

.ActivityPage .history-header .left,
.ActivityPage .history-header .right {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}

.ActivityPage .history-header .right {
  justify-content: flex-end;
}

.ActivityPage .history-header .left .ui.header.sub {
  margin: 0px;
}

.ActivityPage .center {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActivityPage .center p {
  color: var(--secondary-text);
}

.ActivityPage .transactions {
  margin-top: 20px;
}
