.ShortcutsModal {
  position: relative;
}

.ShortcutsModal.ui.modal > .content {
  font-size: 17px;
  max-height: 60vh;
  overflow: auto;
}

.ShortcutsModal .shortcut-list .subtitle {
  color: var(--bluish-steel);
  font-size: 13px;
  text-transform: uppercase;
}

.ShortcutsModal .shortcut-list:not(:first-child) {
  margin-top: 45px;
}

.ShortcutsModal .shortcut {
  margin-top: 16px;
  overflow: hidden;
}

.ShortcutsModal .shortcut .name {
  float: left;
  font-weight: 500;
}

.ShortcutsModal .shortcut .keybinding {
  float: right;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.ShortcutsModal .shortcut .keybinding .plus {
  margin: 0 6px;
}

.ShortcutsModal .Chip + .Chip {
  margin-left: 16px;
}

.ShortcutsModal .hold {
  margin-right: 8px;
}
