.Square {
  border: 3px solid var(--bluish-steel);
  border-radius: 3px;
  background-color: var(--light-blue-steel);
}

.Square.tiny {
  width: 11px;
  height: 11px;
  border-width: 1px;
  border-radius: 1px;
}

.Square.small {
  width: 24px;
  height: 24px;
}

.Square.medium {
  width: 50px;
  height: 50px;
}

.Square.big {
  width: 70px;
  height: 70px;
}
