div.TextAreaField.ParameterField {
  flex-direction: column;
  align-items: normal !important;
}

div.TextAreaField.ParameterField .label {
  margin-bottom: 8px;
}

.TextAreaField textarea {
  background-color: transparent;
  border: none;
  color: white;
  font-size: var(--parameter-font-size);
  resize: none;
}

.TextAreaField textarea:focus {
  outline: none;
}
