.ClaimENSPage .main {
  margin-top: 60px;
}

.ClaimENSPage .content {
  padding: 0 80px;
}

.ClaimENSPage .ui.header.title {
  margin-bottom: 0px;
}

.ClaimENSPage .subtitle {
  margin-top: 8px;
  display: inline-block;
}

.ClaimENSPage .ui.form {
  width: 100%;
}

.ClaimENSPage .ui.form .actions {
  margin-top: 48px;
}

.ClaimENSPage .popup-button {
  margin-left: 16px;
}

.ClaimENSPage .avatar-friends {
  background-image: url('../../images/avatar-friends.svg');
  height: 343px;
  width: 240px;
}

.ClaimENSPage .dcl.section .dcl.field .overlay .ui.button.basic {
  font-size: 17px;
  color: var(--secondary-text) !important;
  cursor: default;
}

.ClaimENSPage .dcl.section.red .dcl.field .overlay .ui.button.basic {
  color: var(--error) !important;
}

.ClaimENSPage .ui.button.primary .dcl.mana {
  font-size: 14px;
  line-height: 0;
}

.ClaimENSPage .content .dcl.mana {
  font-size: 14px;
}

.ClaimENSPage .content .dcl.mana .symbol {
  transform: translateY(-0.1em);
}

.ClaimENSPage .ui.button.primary:not(.loading) .dcl.mana .symbol {
  filter: brightness(100);
  color: var(--text);
  padding-right: 0.1em;
  padding-left: 0.2em;
  transform: translateY(-0.03em);
}
