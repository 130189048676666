.LandAtlas {
  margin: 0px;
  width: 640px;
  align-items: unset;
  justify-items: unset;
  min-height: 540px;
}

.LandAtlas .atlas-container {
  height: 480px;
}

.LandAtlas .thumbnail {
  position: absolute;
  z-index: 99999;
  top: calc(64px + 16px);
  left: 16px;
  border: 2px solid white;
  border-radius: 5px;
  width: 180px;
  height: 100px;
  overflow: hidden;
}

.LandAtlas .thumbnail.disable-rotate .rotate,
.LandAtlas .thumbnail.disable-rotate .rotate .Icon {
  opacity: 0.5;
  cursor: not-allowed;
}

.LandAtlas .thumbnail img {
  width: 100%;
  height: 100%;
}

.LandAtlas .thumbnail .rotate {
  background: rgba(0, 0, 0, 0.4);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.LandAtlas .thumbnail .rotate.clockwise {
  left: 44px;
}

.LandAtlas .actions {
  display: flex;
  align-items: center;
}

.LandAtlas .actions .summary {
  border: 1px solid rgba(var(--bluish-steel-raw), 0.32);
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
  margin: 24px 12px 24px 24px;
  font-weight: 100;
  font-size: 15px;
  flex: 1;
}

.LandAtlas .notice {
  position: absolute;
  z-index: 999999;
  background: var(--card);
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  padding: 16px;
  text-align: center;
  user-select: none;
}

.LandAtlas .notice + .thumbnail {
  top: calc(64px + 56px);
}

.LandAtlas .notice .icon.warning.sign {
  color: var(--danger);
  margin-right: 8px;
}

.LandAtlas .inline-action {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: var(--primary);
  margin-left: 16px;
}

.LandAtlas .actions .ui.button.primary {
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.ui.popup.inverted.publish-disabled {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  margin-top: 20px;
  z-index: 999999999999999;
}

.ui.popup.inverted.publish-disabled:first-line {
  color: white;
}

.ui.visible.popup.inverted.publish-disabled:before {
  right: 40px; /* The arrow needs to be in the middle of the button - kinda */
}
