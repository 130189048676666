.AssetCard.horizontal {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  background: var(--light-blue-steel);
}

.AssetCard.horizontal:hover {
  background: rgba(var(--bluish-steel-raw), 0.72);
}

.AssetCard.horizontal .thumbnail {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 4px;
  position: relative;
}

.AssetCard.horizontal .thumbnail:after {
  width: 20px;
  height: 20px;
}

.AssetCard.horizontal div.title {
  margin-top: 0px;
  color: var(--sidebar-heading);
}
