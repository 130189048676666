.AssetCard {
  cursor: grab;
}

.AssetCard.is-dragging {
  opacity: 0.5;
}

.AssetCard.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.AssetCard.smart {
  background: var(--smart-item);
}

.AssetCard.smart:hover {
  background: var(--smart-item-hover);
}
