.AssetsEditor > .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.AssetsEditor > .actions > button.ui.button.submit {
  margin-left: auto;
}

.AssetsEditor .pagination {
  display: flex;
  align-items: center;
  user-select: none;
}

.AssetsEditor .pagination .current {
  font-size: 12px;
  display: block;
  width: 80px;
  text-align: center;
}

.AssetsEditor .pagination > button {
  padding: 0px;
  width: 36px;
  height: 36px;
  min-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.AssetsEditor .pagination > .ui.icon.button > .icon {
  margin-top: -5px !important;
  transform: scale(1.4);
  color: var(--secondary-text);
}
