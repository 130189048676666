.CenterPanel {
  position: relative;
  flex: 1 1 auto;
  /* background-color: #4c4852; */
}

.CenterPanel .WearablePreview {
  background: none;
}

.CenterPanel .footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.CenterPanel .footer .options {
  margin-bottom: 16px;
}

.CenterPanel .footer .option {
  background-color: var(--gray);
  padding: 4px 12px 6px 12px;
  border-radius: 4px;
  display: inline-block;
  margin-left: 16px;
  cursor: pointer;
}

.CenterPanel .footer .option.active {
  background-color: #ff2d55;
}

.CenterPanel .footer .ui.dropdown > .dropdown.icon {
  color: var(--text);
}

.CenterPanel .footer .ui.dropdown > .text {
  color: var(--text);
  font-size: 11px;
  line-height: 16px;
}

.CenterPanel .footer .avatar-attributes {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  transition: margin-bottom 0.2s ease-in-out;
  background-color: var(--background);
  padding: 16px 16px 4px;
  margin-bottom: -204px;
  border-top: 1px solid rgba(115, 110, 125, 0.3);
}

.CenterPanel .footer .avatar-attributes .dropdown-container {
  flex: 0 50%;
}

.CenterPanel .footer .avatar-attributes .ui.dropdown {
  width: 95%;
}

.CenterPanel .footer .avatar-attributes.active {
  margin-bottom: 0;
}

/* .CenterPanel .footer .ui.dropdown .menu {
  background-color: var(--smart-grey);
} */
