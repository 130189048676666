.EntityField {
  user-select: none;
}

.EntityField .search-field {
  opacity: 1 !important; /* Overwrite disabled */
}

.EntityField .search-field .results {
  display: none !important;
}

.EntityField .search-field i.icon {
  color: rgb(var(--purplish-grey-raw));
  margin-left: 4px;
}

.EntityField .ui.search input.prompt::placeholder {
  color: rgb(var(--purplish-grey-raw));
}

.EntityField .ui.search input.prompt,
.EntityField .ui.search.focus input.prompt {
  background: var(--dark-two);
  color: rgb(var(--purplish-grey-raw));
  line-height: 37px;
  height: 36px;
  border-radius: 4px;
}

.EntityField .dcl.select-field .header {
  margin: 0px !important;
  padding: 0px 10px !important;
}

.EntityField .dcl.select-field .header .ui.input input {
  font-size: 14px !important;
  width: 228px;
}

.EntityField .no-results {
  text-align: center !important;
  cursor: default !important;
  opacity: 0.4;
}

.EntityField .no-results:hover {
  background: transparent !important;
}

.EntityField .trigger {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
}

.EntityField .trigger .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}

.EntityField .trigger img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  flex: unset;
}

.EntityField .dcl.select-field {
  min-width: 0px;
  min-height: 0px;
  width: 100%;
}

.EntityField .dcl.select-field .message {
  display: none;
}

.EntityField .dcl.select-field .ui.dropdown .dropdown.icon {
  position: relative;
  padding: 0px;
  margin: 0px;
  top: 0;
  left: 0;
  padding: 8px;
}

.EntityField .dcl.select-field .ui.dropdown {
  border: 0px;
  padding: 0px;
  min-height: 0px;
  min-width: 0px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.EntityField .dcl.select-field .ui.dropdown .menu {
  width: 250px;
}

.EntityField .dcl.select-field .ui.dropdown .menu > .item {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
}

.EntityField .dcl.select-field .ui.selection.active.dropdown,
.EntityField .dcl.select-field .ui.selection.active.dropdown:hover {
  border-bottom: 0px;
}

.EntityField .dcl.select-field .ui.dropdown > div.text {
  font-size: var(--parameter-font-size);
  font-weight: normal;
}
