.SmartIcon {
  position: relative;
  display: inline-block;
  background-image: url(./smart.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 16px;
  height: 16px;
}
