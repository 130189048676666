.Preview-wrapper {
  position: relative;
  height: 100%;
  flex: 1;
}

.Preview-wrapper .ui.loader:before {
  border: none;
  font-size: 12px;
  top: 22px;
}

.Preview-wrapper .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--preview-background);
  z-index: var(--preview-z-index);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Preview-wrapper .loading-text {
  margin-top: 20px;
}

.Preview {
  flex: 1;
  height: 100%;
  position: relative;
}

.Preview > canvas.dcl-canvas {
  display: block;
  outline: none;
}

.Preview > canvas.recording {
  visibility: hidden !important;
  width: 1280px !important;
  height: 720px !important;
}

.Preview.loading > canvas {
  position: absolute !important;
}

.Preview-wrapper .overlay .progress {
  height: 5px;
  width: 20%;
  margin-top: 64px;
  border-radius: 4px;
  overflow: hidden;
}

.Preview-wrapper .overlay .full {
  float: left;
  width: 0%;
  height: 100%;
  display: inline-flex;
  background-color: #eb455a;
  background-color: rgba(var(--purplish-grey-raw), 0.5);
}

.Preview-wrapper .overlay .progress.loaded {
  z-index: 1;
}

.Preview-wrapper .overlay .progress.ingame .full {
  animation: progress 10s forwards;
}

.Preview-wrapper .ui.loader:before {
  animation: progress_spinner 10s forwards;
}

@keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes progress_spinner {
  0% {
    content: '0%';
  }
  10% {
    content: '10%';
  }
  20% {
    content: '20%';
  }
  30% {
    content: '30%';
  }
  40% {
    content: '40%';
  }
  50% {
    content: '50%';
  }
  60% {
    content: '60%';
  }
  70% {
    content: '70%';
  }
  80% {
    content: '80%';
  }
  90% {
    content: '90%';
  }
  100% {
    content: '100%';
  }
}
