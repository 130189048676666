.SidebarCollection {
  margin-top: 8px;
  padding: 0 16px;
}

.SidebarCollection > a {
  display: flex;
  align-items: center;
  background: rgba(var(--bluish-steel-raw), 0.2);
  height: 72px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 8px;
}

.SidebarCollection .CollectionImage {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 8px;
  flex: none;
}

.SidebarCollection .wrapper {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.SidebarCollection .name {
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  color: var(--text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SidebarCollection .count {
  font-size: 15px;
  line-height: 24px;
  color: var(--secondary-text);
}

.SidebarCollection:hover > a {
  background: var(--light-blue-steel);
}

.SidebarCollection.is-selected > a {
  border: 1px solid rgba(var(--bluish-steel-raw), 0.7);
}

.SidebarCollection.is-over > a {
  border: 1px solid var(--primary);
}

.SidebarCollection.no-drop > a {
  cursor: no-drop;
}

.SidebarCollection .CollectionStatus {
  margin-right: 6px;
}

.CollectionImage .item-row.empty {
  overflow: hidden;
  border-radius: 8px;
}

.SidebarCollection .CollectionImage .empty > * {
  display: none;
}
