.TextField .dcl.field {
  border: 0px;
  min-width: 0px;
  padding: 0px;
}

.TextField .dcl.field .ui.input {
  border: 0px;
  min-width: 0px;
  padding: 0px;
}

.TextField .dcl.field .ui.input input {
  font-weight: normal;
  font-size: var(--parameter-font-size);
  padding: 0px;
  line-height: 0px;
  border-bottom: 0px;
}

.TextField .dcl.field .ui.input input:focus,
.TextField .dcl.field .ui.input input:active {
  border-bottom: none;
}

.TextField .dcl.field .message {
  display: none;
}
