.FileImport .dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  height: 276px;
  width: 100%;
  background-color: rgba(var(--dark-raw), 0.2);
  outline: none;
  user-select: none;
  overflow: auto;
  position: relative;
}

.FileImport .dropzone .action {
  display: block;
  color: var(--primary);
  cursor: pointer;
  text-align: center;
}

.FileImport .image {
  width: 64px;
  height: 64px;
  background: url('../../images/import.svg') no-repeat;
  background-size: cover;
  margin-bottom: 32px;
}

.FileImport .cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
