.CollectionsPage .ui.cards {
  margin: 0px;
}

.CollectionsPage .ui.cards > .empty {
  margin-top: 28px;
  padding: 112px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--divider);
  justify-content: center;
  color: var(--secondary-text);
  text-align: center;
  line-height: 28px;
  font-size: 17px;
}

.CollectionsPage .ui.cards > .empty .header.title {
  margin: 0;
}

.CollectionsPage .ui.cards > .empty .empty-description {
  margin: 8px auto 34px;
  width: 500px;
}

.CollectionsPage .ui.cards > .empty .create-new-wrapper {
  display: flex;
  justify-content: center;
}

.CollectionsPage .ui.cards > .empty .create-new {
  width: 240px;
  height: 204px;
  position: relative;
  background-color: rgba(var(--bluish-steel-raw), 0.16);
  border-radius: 8px;
  background-position: 50% 35%;
  cursor: pointer;
}

.CollectionsPage .ui.cards > .empty .create-new:first-child {
  margin-right: 24px;
}

.CollectionsPage .ui.cards > .empty .create-new > .text {
  position: absolute;
  flex: 1 1;
  color: #fff;
  bottom: 31px;
  width: 100%;
}

.CollectionsPage .ui.cards > .empty .create-new-item {
  background-image: url('../../images/hat.svg');
  background-repeat: no-repeat;
}
.CollectionsPage .ui.cards > .empty .create-new-collection {
  background-image: url('../../images/folder-hat.svg');
  background-repeat: no-repeat;
}

.CollectionsPage .ui.cards .ui.card {
  width: 248px;
  background-color: transparent;
  box-shadow: none;
}

.CollectionsPage .card .CollectionImage {
  height: 248px;
}

.CollectionsPage .card .image-wrapper {
  height: 248px;
  padding: 15px;
}

.CollectionsPage .filters .dcl.row.actions .ui.dropdown .ui.button {
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  padding: 5px 10px;
  border-radius: 4px;
  border: solid 1px var(--gray);
}

.CollectionsPage .filters .dcl.row.actions .ui.button.open-editor {
  padding: 6px 13px;
}

.CollectionsPage .filters .dcl.row.actions .ui.button.open-editor:hover {
  transform: translateY(0px);
}

.CollectionsPage .Chip {
  padding: 4px;
  height: 28px;
  width: 32px;
}

.CollectionsPage .Chip.grid {
  border-radius: 4px 0px 0px 4px;
  margin-left: 16px;
}

.CollectionsPage .Chip.list {
  border-radius: 0px 4px 4px 0px;
}

.CollectionsPage .is-card {
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  box-shadow: none;
  transform: translateY(0px);
  margin-top: 20px;
  margin-right: 24px;
}

.CollectionsPage .is-card:nth-child(4n) {
  margin-right: 0px;
}

.CollectionsPage .is-card:hover {
  transform: translateY(-4px);
}

.CollectionsPage .is-card .is-image {
  transition: box-shadow 0.2s ease-in-out;
  height: 248px;
  width: 248px;
  overflow: hidden;
  border-radius: 8px !important;
}

.CollectionsPage .is-card:hover .is-image {
  box-shadow: var(--shadow-2);
}

.CollectionsPage .is-card .is-image .is-image {
  border-radius: 0px !important;
  box-shadow: none;
}

@media (max-width: 1200px) {
  .CollectionsPage .is-card .is-image {
    width: 215px;
    height: 215px;
  }
}

.CollectionsPage .dcl.tabs.fullscreen .dcl.column.right {
  float: right;
  padding: 19px 0;
}

.CollectionsPage .pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.CollectionsPage .ui.pagination.menu {
  margin-top: 16px;
}

.CollectionsPage .dcl.tabs .ui.container {
  margin-bottom: 20px;
}
