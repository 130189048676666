.ui.small.modal.EditProjectModal {
  width: 560px;
}

.EditProjectModal .picker {
  margin-bottom: 31px;
}

.EditProjectModal .ui.sub.header.picker-label {
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.EditProjectModal .LayoutPicker {
  padding: 15px;
}

.EditProjectModal .LayoutPicker .input {
  width: 40%;
}

.EditProjectModal .error {
  font-size: 12px;
  color: red;
}
