.DeployToPool {
  margin: 0px 24px 24px 24px;
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DeployToPool .ui.form .dcl.field {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5em;
}

.DeployToPool .dcl.field input {
  text-align: center;
}

.DeployToPool .modal-subtitle {
  margin-bottom: 48px;
}

.DeployToPool .form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DeployToPool .terms {
  margin-bottom: 24px;
  color: var(--bluish-steel);
}

.DeployToPool .error {
  margin-bottom: 24px;
  color: red;
}

.DeployToPool .ui.button.submit {
  width: 240px;
}

.DeployToPool.progress {
  margin: 24px 24px 24px 24px;
  width: 480px;
}

.DeployToPool.success {
  margin: 0px 0px 24px 0px;
  overflow: hidden;
  width: 580px;
}

.DeployToPool.success .preview {
  width: 100%;
  height: 300px;
  display: block;
  object-fit: cover;
  margin-bottom: 24px;
}

.DeployToPool.success .ui.button + .ui.button {
  margin-left: 20px;
}
