.AssetImporter {
  display: flex;
  flex-direction: column;
}

.AssetImporter .FileImport {
  margin-bottom: 20px;
}

.AssetImporter .single-project {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.AssetImporter .multiple-projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100%;
  align-items: flex-start;
  padding: 24px;
}

.AssetImporter .multiple-projects .AssetThumbnail:not(:nth-child(6n)) {
  margin-bottom: 32px;
  margin-right: 24px;
}

.AssetImporter .multiple-projects .AssetThumbnail .title {
  font-size: 15px;
}

.AssetImporter .submit {
  align-self: flex-end;
}

.AssetImporter .FileImport .dropzone .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
}
