.AssetCard.vertical {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
  background: var(--light-blue-steel);
  padding: 16px 10px;
  height: 105px;
}

.AssetCard.vertical.ground {
  padding: 0px;
  overflow: hidden;
}

.AssetCard.vertical:hover {
  background: rgba(var(--bluish-steel-raw), 0.64);
}

.AssetCard.vertical .thumbnail {
  width: 63px;
  height: 62px;
}

.AssetCard.vertical.ground .thumbnail {
  width: 100%;
  height: 100%;
}

.AssetCard.vertical .badge {
  border-radius: 8px;
  background-color: #c640cd;
  width: 24px;
  height: 24px;
  padding: 4px;
  position: absolute;
  right: 12px;
  bottom: 12px;
}
