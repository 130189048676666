.WalletSignIn {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  align-self: center;
  flex-direction: column;
  padding-right: 16px;
}

.WalletSignIn .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.WalletSignIn .main:only-child {
  flex: 1;
}

.WalletSignIn .connect {
  flex: none;
}

.WalletSignIn .error {
  visibility: hidden;
  user-select: none;
  color: var(--primary);
  margin-top: 20px;
}

.WalletSignIn .error.visible {
  visibility: visible;
}

.WalletSignIn .message {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  margin: 24px 20px;
}

.WalletSignIn .StarWalletIcon {
  margin-top: 40px;
  width: 140px;
  height: 140px;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  flex: none;
}

.WalletSignIn .StarWalletIcon svg {
  transform: scale(0.75);
}
