.Popup {
  position: absolute;
  z-index: 100;
  padding: 20px;
  background: black;
  margin-left: -320px;
  margin-top: -60px;
  width: 280px;
  transition: opacity 0.2s ease, top 0.2s ease, left 0.2s ease;
  border-radius: 8px;
  user-select: none;
  pointer-events: none;
}

.Popup .land-name .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.Popup .dcl.badge {
  font-size: 12px;
  line-height: 13px;
  padding: 3px 5px;
  margin-left: 8px;
  height: 24px;
  font-weight: 500;
  flex: none;
}

.Popup .dcl.section.online-scenes {
  margin-bottom: 0px;
}

.Popup .pin {
  background-image: url(../../../icons/pin.svg);
  width: 17px;
  height: 16px;
  background-size: 19px;
  background-position: -2px -1px;
}

.Popup .scene {
  margin-bottom: 8px;
}

.Popup .scene:last-child {
  margin-bottom: 0px;
}

.Popup .scene .thumbnail {
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-color: var(--card);
  flex: none;
}

.Popup .scene .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Popup .operators .Profile {
  display: block;
  margin-bottom: 8px;
}

.Popup .operators .Profile:last-child {
  margin-bottom: 0px;
}
