.ShareButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ShareButton .ui.button.mini {
  font-size: 11px;
  line-height: 16px;
  padding: 4px 12px;
  border-radius: 4px;
  height: 100%;
  min-width: 80px;
  background: rgba(var(--bluish-steel-raw), 0.64);
}

.ShareButton .ui.button.mini,
.ShareButton .ui.button.mini:active,
.ShareButton .ui.button.mini:focus,
.ShareButton .ui.button.mini:hover {
  transform: translateY(0px);
}
