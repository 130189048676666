.RightPanel {
  width: var(--item-editor-panel-width);
  flex: none;
  background-color: var(--background);
  border-left: 1px solid rgba(115, 110, 125, 0.3);
  padding: 16px;
  position: relative;
}

.RightPanel > .header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.RightPanel > .header .title {
  flex: 1 0 auto;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: var(--secondary-text);
}

.RightPanel .details {
  display: flex;
  border: 1px solid var(--gray);
  border-radius: 6px;
  padding: 8px;
  align-items: stretch;
  position: relative;
}

.RightPanel .details .edit-item-file {
  position: absolute;
  z-index: 9;
  top: 8px;
  right: 8px;
  filter: brightness(100);
}

.RightPanel .details .ItemImage {
  flex: none;
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 6px;
}

.RightPanel .details .thumbnail-container {
  position: relative;
}

.RightPanel .details .thumbnail-container .Icon.camera {
  position: absolute;
  top: 40%;
  left: 36%;
  cursor: pointer;
}

.RightPanel .details .thumbnail-container .thumbnail-edit-background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background);
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.RightPanel .details .thumbnail-edit-container {
  display: none;
}

.RightPanel .details .thumbnail-container:hover .thumbnail-edit-container {
  display: block;
}

.RightPanel .details .thumbnail-container input[type='file'] {
  display: none;
}

.RightPanel .details .metrics {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.RightPanel .details .metrics .metric {
  color: var(--secondary-text);
  font-size: 13px;
  line-height: 18px;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position-y: center;
  text-transform: uppercase;
}

.RightPanel .details .metrics .metric.triangles {
  background-image: url(../../../images/triangles.svg);
}

.RightPanel .details .metrics .metric.materials {
  background-image: url(../../../images/materials.svg);
}

.RightPanel .details .metrics .metric.textures {
  background-image: url(../../../images/textures.svg);
}

.RightPanel > .header > .ui.dropdown {
  position: absolute;
  right: 0px;
}

.RightPanel .actions {
  flex: none;
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(../../../images/optons-right-panel.svg);
  background-size: 18px;
}

.RightPanel .edit-buttons {
  margin: 10px 0;
  justify-content: center;
  display: flex;
}

.RightPanel .edit-buttons .ui.button + .ui.button {
  margin-left: 20px;
}

.RightPanel .danger-text {
  word-break: break-word;
}

.RightPanel .details .download-item-button {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.RightPanel .details .ui.tiny.active.loader.donwload-item-loader {
  right: 1px;
  bottom: 0px;
  top: auto;
  left: auto;
}
