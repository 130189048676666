.LeftPanel {
  width: var(--item-editor-panel-width);
  flex: none;
  background-color: var(--background);
  border-right: 1px solid rgba(115, 110, 125, 0.3);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.LeftPanel > .empty {
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 32px;
}

.LeftPanel > .empty .subtitle {
  color: var(--secondary-text);
}

.LeftPanel .tabs {
  padding: 0 16px;
}

.LeftPanel .dcl.tabs.fullscreen {
  margin-bottom: 8px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.LeftPanel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.LeftPanel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
