.SidebarHeader {
  color: var(--sidebar-heading);
  font-size: 17px;
  user-select: none;
}

.SidebarHeader.ui.header.medium {
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SidebarHeader .item-drawer-type-buttons .Chip:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.SidebarHeader .item-drawer-type-buttons .Chip:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.SidebarHeader .item-drawer-type-buttons {
  float: right;
}

.SidebarHeader .item-drawer-type-buttons .Chip {
  float: left;
}

.SidebarHeader .selected-scope {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
}

.SidebarHeader .selected-scope .title {
  margin-left: auto;
  margin-right: auto;
}

.SidebarHeader .selected-scope .spacer {
  width: 46px;
}

.SidebarHeader .selected-scope .icon {
  height: 1.3em;
  margin-right: 10px;
}

.SidebarHeader.ui.header.medium .title {
  font-size: 17px;
  flex: 1 0 auto;
}

.SidebarHeader .Icon {
  transform: scale(1.3) translate(-3px);
}
