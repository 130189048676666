.ItemDragLayer,
.toolbar > div.ItemDragLayer {
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 2000;
}

.ItemDragLayer .thumbnail {
  width: 64px;
  height: 64px;
  position: absolute;
  top: -32px;
  left: -32px;
  border-radius: 8px;
}

.ItemDragLayer .thumbnail.empty {
  background: var(--light-blue-steel);
}

.ItemDragLayer .thumbnail.empty:after {
  display: block;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  background-color: rgba(var(--bluish-steel-raw), 0.28);
  border-radius: 100%;
  content: '';
}
