.AssetPackEditor .assetpack {
  display: flex;
}

.AssetPackEditor .assetpack .dcl.field {
  width: 100%;
}

.AssetPackEditor .assetpack .thumbnail {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  border: 2px solid var(--divider);
  margin-right: 24px;
  flex: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AssetPackEditor .assetpack .thumbnail .Icon {
  position: relative;
}

.AssetPackEditor .assetpack .thumbnail img + .Icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  transform: scale(0.8);
}

.AssetPackEditor .assetpack .thumbnail img {
  width: 100%;
  height: 100%;
}

.AssetPackEditor .assetpack .thumbnail input {
  display: none;
}

.AssetPackEditor .assets .header {
  display: flex;
  justify-content: space-between;
  margin: 24px 0px 16px 0px;
}

.AssetPackEditor .assets .content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100%;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 332px;
  padding: 15px 0px;
}

.AssetPackEditor .assets .content .AssetThumbnail:not(:nth-child(6n)) {
  margin-bottom: 32px;
  margin-right: 24px;
}

.AssetPackEditor .assets .content .AssetThumbnail {
  margin-right: 24px;
}

.AssetPackEditor > .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.AssetPackEditor > .actions > button.ui.button.submit {
  margin-left: auto;
}

.AssetPackEditor .no-items {
  display: flex;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--divider);
  align-items: center;
  justify-content: center;
  color: var(--secondary-text);
  user-select: none;
  text-align: center;
  line-height: 28px;
  margin-bottom: 48px;
  flex-direction: column;
}

.AssetPackEditor > .error {
  color: red;
  text-align: center;
  font-size: 13px;
  background: var(--background);
  border-radius: 6px;
  padding: 12px;
}
