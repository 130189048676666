.ENSListPage .filters {
  margin-bottom: 16px;
}

.ENSListPage .TableRow {
  cursor: default;
}

.ENSListPage .empty-names {
  border: 1px solid var(--divider) !important;
  border-radius: 12px !important;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text);
  user-select: none;
  text-align: center;
  line-height: 28px;
  font-size: 17px;
}

.ENSListPage .Icon.right-round-arrow {
  margin-bottom: -2px;
}

.ENSListPage .TableRow .dcl.column.subdomain-wrapper {
  display: flex;
  flex: none;
  flex-direction: row;
}

.ENSListPage .subdomain-wrapper .Icon.profile {
  margin-left: 10px;
}

.ENSListPage .ui.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ENSListPage .ui.pagination .item[type='nextItem'],
.ENSListPage .ui.pagination .item[type='prevItem'] {
  position: absolute;
  background-color: var(--secondary);
}

.ENSListPage .ui.pagination .item[type='prevItem'] {
  left: 0px;
}

.ENSListPage .ui.pagination .item[type='nextItem'] {
  right: 0px;
}

.ENSListPage .pagination-section {
  width: 100%;
}
.ENSListPage .ui.sub.header.items-count {
  flex: 1 0 auto;
  color: var(--secondary-text);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.ENSListPage .actions {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
}

.ENSListPage .actions .ui.button.basic {
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  padding: 5px 10px;
  border-radius: 4px;
  border: solid 1px var(--gray);
}

.ENSListPage .claim-name .Icon {
  display: block;
}

.ENSListPage .ui.dropdown {
  margin-right: 20px;
}
