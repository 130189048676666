.ui.modal.ApprovalFlowModal .content {
  max-height: 500px;
  overflow-y: auto;
}

.ui.modal.ApprovalFlowModal .content.loading {
  height: 100px;
}

.ui.modal.ApprovalFlowModal .content.loading .ui.loader {
  margin-top: 32px;
}

.ui.modal.ApprovalFlowModal .content .item .name {
  display: flex;
  align-items: center;
}

.ui.modal.ApprovalFlowModal .content .item .name .ItemImage {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.ui.modal.ApprovalFlowModal .content .item .name .ItemImage .item-image {
  width: 48px;
  height: 48px;
}

.ui.modal.ApprovalFlowModal .size {
  color: var(--secondary-text);
}

.ui.modal.ApprovalFlowModal .content.error {
  font-family: monospace;
  color: var(--text);
  background-color: var(--secondary-on-modal-hover);
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 16px;
}

.ui.tiny.visible.warning.message.warning-rescue-message {
  margin-left: 32px;
  margin-right: 32px;
}
