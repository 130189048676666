.AssetPackList .create-asset-pack {
  background-color: var(--modal);
  padding: 13px;
  text-align: center;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  width: calc(var(--sidebar-width) - 32px);
}

.AssetPackList .create-asset-pack {
  background-color: var(--modal);
  padding: 13px;
  text-align: center;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
}

.AssetPackList .create-asset-pack .Icon {
  margin-right: 12px;
}

.AssetPackList .create-asset-pack:hover {
  cursor: pointer;
  background-color: rgba(var(--bluish-steel-raw), 0.72);
}
