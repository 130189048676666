.CollectionCard .content {
  margin-top: 16px;
  text-align: center;
}

.CollectionCard .content .text {
  font-size: 17px;
  max-width: 248px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CollectionCard .subtitle {
  margin-top: 5px;
  max-width: 248px;
  color: var(--secondary-text);
}

.CollectionCard .CollectionBadge {
  margin-left: 5px;
}

.CollectionCard .sparkles {
  width: 54px;
  height: 56px;
  margin-bottom: 5px;
  background-image: url('../../../images/sparkles.svg');
  background-repeat: no-repeat;
}

.CollectionCard.is-over .CollectionImage {
  border: 1px solid var(--primary) !important;
}

.CollectionCard .empty-collection-options .icon:before {
  color: var(--text) !important;
}

.CollectionCard:hover .options-dropdown.ui.dropdown > .dropdown.icon {
  visibility: visible;
}

.CollectionCard .options-dropdown.ui.dropdown > .dropdown.icon {
  visibility: hidden;
}

@media (max-width: 1200px) {
  .CollectionCard .content .text {
    max-width: 215px;
  }
}

.delete-collection-confirm .header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
