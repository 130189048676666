.LandOperatorForm.ui.form .dcl.field .ui.input.revoked input {
  text-decoration: line-through;
  color: var(--secondary-text);
}

.LandOperatorForm .cancel {
  margin-right: 16px;
}

.LandOperatorForm.ui.form .dcl.field .ui.input input {
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
}

.LandOperatorForm.ui.form .dcl.field .ui.input.is-revokable input {
  padding-right: 86px;
}
