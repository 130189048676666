.itemImage {
  min-width: 64px;
  max-width: 64px;
  height: 64px;
  padding: 5px;
  margin-right: 24px;
}

.itemStatus {
  margin-right: 4px;
}

.mana {
  font-size: 15px !important;
  font-weight: normal;
  line-height: 0;
}

.grid:hover .action {
  display: inherit !important;
  margin-left: 5px;
}

.grid {
  padding: 10px 0 !important;
  color: var(--text);
  border-bottom: 1px solid var(--divider);
}

.grid:hover {
  background-color: rgba(36, 33, 41, 0.4);
  color: var(--text);
}

.row {
  align-items: center;
  font-size: 15px;
}

.column {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
}

.avatarColumn {
  align-items: center;
  flex-direction: inherit !important;
}

.info {
  width: calc(100% - 80px);
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.name {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkAction {
  text-transform: uppercase;
}

.subtitle {
  margin-top: 2px;
  color: var(--secondary-text);
  text-transform: uppercase;
}

.itemActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.action {
  display: none !important;
}

.done {
  text-align: right;
  color: rgb(var(--light-blue-raw));
}

.check {
  margin-left: 2px;
}
