.Manager {
  display: flex;
  padding: 10px;
  align-items: center;
}

.Manager:hover {
  background-color: rgba(22, 20, 26, 0.5);
}

.Manager .info {
  flex: 1 1 auto;
  display: flex;
}

.Manager .action {
  display: none;
  font-size: 13px;
}

.Manager:hover .action {
  display: block;
}

.Manager .Profile {
  width: 120px;
  margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Manager .Profile .name {
  margin-left: 4px;
}
