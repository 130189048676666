.SidebarSearch {
  position: relative;
  padding-right: 16px;
}

.SidebarSearch .ui.search-input {
  width: calc(100% - 44px);
  margin-bottom: 12px;
}

.SidebarSearch .ui.search-input input,
.SidebarSearch .ui.search-input input:focus {
  outline: none;
  background: rgba(var(--bluish-steel-raw), 0.2);
  border: none;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  color: var(--text);
  padding-left: 32px;
}

.SidebarSearch .ui.icon.input > i.icon.close {
  cursor: pointer;
  pointer-events: inherit;
  color: var(--bluish-steel);
}

.SidebarSearch .search.icon {
  color: var(--bluish-steel);
  position: absolute;
  left: 8px;
  top: 8px;
}

.SidebarSearch.disabled {
  opacity: 0.7;
}

.SidebarSearch .scripts-toggle {
  width: 32px;
  height: 32px;
  background-color: var(--smart-grey);
  display: inline-block;
  border-radius: 8px;
  position: absolute;
  top: 0px;
  right: 16px;
  padding: 8px;
  cursor: pointer;
}

.SidebarSearch .scripts-toggle:hover {
  background: var(--bluish-steel);
}

.SidebarSearch .scripts-toggle.active {
  background-color: #c640cd;
}
