.CollectionImage .item-row,
.CollectionImage .item-row .image-wrapper {
  display: flex;
  align-items: center;
}

.CollectionImage .item-row.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.CollectionImage .item-row.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: var(--secondary-text);
  border: 1px solid var(--divider);
  border-radius: 5px;
  text-transform: uppercase;
}

.CollectionImage .item {
  max-height: 100%;
}

.CollectionImage .item-row > .ItemImage.image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.CollectionImage .item-image {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.CollectionImage .sparkles {
  width: 54px;
  height: 56px;
  margin-bottom: 5px;
  background-image: url('../../images/sparkles.svg');
  background-repeat: no-repeat;
}
