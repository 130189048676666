.ui.table .CollectionRow td {
  padding: 8px 0;
}

.CollectionRow:hover {
  background-color: rgba(36, 33, 41, 0.4);
  color: var(--text);
  cursor: pointer;
}

.CollectionRow .CollectionImage {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  overflow: hidden;
  flex: none;
}

.CollectionRow .info {
  width: calc(100% - 80px);
  margin-left: 20px;
}

.CollectionRow .info .title .CollectionStatus {
  margin-right: 4px;
}

.CollectionRow .name {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CollectionRow .title {
  font-weight: 500;
}

.CollectionRow .subtitle {
  margin-top: 2px;
  display: block;
  color: var(--secondary-text);
}

.CollectionRow .info .title,
.CollectionRow .image-column {
  display: flex;
  align-items: center;
}

.CollectionRow .text-centered {
  display: flex;
  justify-content: center;
}

.CollectionRow .assignee-container {
  display: flex;
  flex-direction: column;
}

.CollectionRow .actions .action-text {
  margin-right: 5px;
}

.CollectionRow .actions .action.approved {
  text-align: right;
  color: rgb(var(--light-blue-raw));
}

.CollectionRow .edit-container {
  display: flex;
}

.CollectionRow .edit-container .curator-name {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CollectionRow .pencil.icon {
  padding-left: 6px;
}

.CollectionRow .actions .action.rejected,
.CollectionRow .actions .action.disabled {
  color: var(--secondary-text);
}

.CollectionRow .actions .action.rejected .Icon.close,
.CollectionRow .actions .action.disabled .Icon.close {
  filter: brightness(0.5);
}
