.SidebarCard {
  display: flex;
  width: calc(var(--sidebar-width) - 32px);
  height: 80px;
  align-items: center;
  padding: 0px 16px;
  border-radius: 8px;
  background: var(--light-blue-steel);
  cursor: pointer;
  margin-bottom: 8px;
  user-select: none;
  position: relative;
}

.SidebarCard .new-badge {
  text-transform: uppercase;
  background-color: var(--primary);
  position: absolute;
  font-size: 9px;
  line-height: 14px;
  padding: 0px 4px;
  border-radius: 3px;
  top: 14px;
  right: 271px;
}

.SidebarCard:last-child {
  margin-bottom: 0;
}

.SidebarCard:hover {
  background: rgba(var(--bluish-steel-raw), 0.72);
}

.SidebarCard .thumbnail {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 8px;
}

.SidebarCard img.thumbnail:after {
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  background-color: rgba(var(--bluish-steel-raw), 0.28);
  border-radius: 100%;
  content: '';
}

.SidebarCard .ui.header.small.title {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--sidebar-heading);
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  text-transform: capitalize;
}

.SidebarCard .Icon {
  margin-left: auto;
}
