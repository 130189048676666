.AssetThumbnail {
  position: relative;
  width: 80px;
}

.AssetThumbnail.smart img {
  background: var(--smart-item);
}

.AssetThumbnail.clickable {
  cursor: pointer;
}

.AssetThumbnail .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AssetThumbnail .close-button {
  visibility: hidden;
  width: 18px;
  height: 18px;
  background: var(--primary);
  border-radius: 100%;
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.AssetThumbnail:hover .close-button {
  visibility: visible;
}

.AssetThumbnail .Icon.close {
  transform: scale(0.6);
}

.AssetThumbnail img {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  object-fit: cover;
  background-color: var(--light-blue-steel);
  padding: 10px;
  width: 80px;
  height: 80px;
}

.AssetThumbnail.ground img {
  padding: 0px;
}

.AssetThumbnail .title {
  margin-top: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.AssetThumbnail.error .error {
  color: red;
  font-size: 13px;
  margin-top: 0px;
  text-align: center;
}

.AssetThumbnail.error .error-icon {
  width: 80px;
  height: 80px;
  border: 2px solid rgba(115, 110, 125, 0.24);
  border-radius: 6px;
  background: url('../../images/import-invalid-file.svg') no-repeat;
  display: flex;
  background-position: 50% 50%;
  background-size: 30%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}
