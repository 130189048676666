.HomePageAd {
  padding-top: 32px;
}

.HomePage {
  font-size: 17px;
}

.HomePage .project-cards {
  position: relative;
}

.HomePage .subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HomePage .empty-projects {
  display: flex;
  width: 100%;
  height: 200px;
  border-radius: 12px;
  border: 1px solid var(--divider);
  align-items: center;
  justify-content: center;
  color: var(--secondary-text);
  user-select: none;
  text-align: center;
  line-height: 28px;
}

.HomePage .template-cards {
  margin-top: 80px;
}

.HomePage .template-cards.animate .TemplateCard {
  animation: templateAnimation 2s;
}

.HomePage .project-cards .subtitle,
.HomePage .template-cards .subtitle {
  font-size: 20px;
}

.HomePage .CardList {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.HomePage .project-cards .ProjectCard:nth-child(4n) {
  margin-right: 0;
}

.HomePage .ProjectCard {
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
}

.HomePage .ProjectCard:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.24);
  transform: translateY(-4px);
}
.HomePage .dcl.tabs,
.HomePage .dcl.tabs .ui.container {
  position: relative;
}

.HomePage .projects-menu {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.HomePage .projects-menu .items-count,
.HomePage .scene-pool-menu .ui.header.sub {
  flex: 1 0 auto;
  color: var(--secondary-text);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.HomePage .projects-menu .actions {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
}

.HomePage .projects-menu .actions .ui.button {
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  padding: 5px 10px;
  border-radius: 4px;
  border: solid 1px var(--gray);
}

.HomePage .projects-menu .actions .ui.button + .ui.button {
  margin-left: 4px;
}

.HomePage .projects-menu .actions .ui.dropdown.active.visible {
  z-index: 99999;
}

.HomePage .projects-menu .actions .ui.dropdown + .ui.button {
  margin-left: 24px;
}

.HomePage .project-cards.has-pagination .CardList {
  min-height: 606px;
}

.HomePage .project-cards .ui.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.HomePage .project-cards .ui.pagination {
  margin-top: 40px;
}

.HomePage .project-cards .ui.pagination .item[type='nextItem'],
.HomePage .project-cards .ui.pagination .item[type='prevItem'] {
  position: absolute;
  background-color: var(--secondary);
}

.HomePage .project-cards .ui.pagination .item[type='prevItem'] {
  left: 0px;
}

.HomePage .project-cards .ui.pagination .item[type='nextItem'] {
  right: 0px;
}

.HomePage .SyncToast {
  top: 77px;
}

.HomePage .scene-pool-projects {
  display: flex;
  overflow: auto;
  padding-top: 20px;
}

.HomePage .scene-pool-projects .ProjectCard {
  flex: none;
}

@keyframes templateAnimation {
  0% {
    background: rgba(var(--bluish-steel-raw), 0.16);
  }
  25% {
    background: rgba(var(--bluish-steel-raw), 0.3);
  }
  50% {
    background: rgba(var(--bluish-steel-raw), 0.16);
  }
  75% {
    background: rgba(var(--bluish-steel-raw), 0.3);
  }
  100% {
    background: rgba(var(--bluish-steel-raw), 0.16);
  }
}
